import React from "react"
import { Helmet } from "react-helmet"
import favicon from "../images/favicon.png"
import Header from "../components/homeComponents/Header"
import Footer from "../components/homeComponents/Footer"

// Animate Effect -->
import "../styles/global/animate.css"
import "../styles/global/hover.css"

//  For Image Preview -->
import "../styles/global/magnific-popup.css"

// // Slick Carousel -->
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// BootStrap -->
import "../styles/global/bootstrap.min.css"
import "../styles/global/normalize.css"

//  Main Style -->
import "../styles/global/global.css"
import "../styles/global/responsive.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header />
      <body>{children}</body>
      <Footer />
    </>
  )
}

export default Layout
