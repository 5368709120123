import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons"
import "../../styles/homeComponentStyles/footer.css"

const Footer = () => {
  return (
    <footer className="footertwo-section">
      <div className="container">
        <div className="row">
          {/* <!-- Start: logo --> */}
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer_logo">
              <StaticImage
                src="../../images/logo.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <ul>
                <li>
                  <Link to="">(+75) 36 6552 9564 </Link>
                </li>
                <li>
                  <Link to="services.html">contact@domain.com</Link>
                </li>
                <li>
                  <Link to="services.html">Washington, United State</Link>
                </li>
              </ul>
              <div className="footer_socil">
                <ul>
                  <li>
                    <Link to="#">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FontAwesomeIcon icon={faTwitter} />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FontAwesomeIcon icon={faInstagram} />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FontAwesomeIcon icon={faPinterestP} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- End: logo --> */}

          {/* <!-- Start: Quick Link --> */}
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="foo_widgetquick_lnk">
              <h5>Category</h5>
              <ul>
                <li>
                  <Link to="courses.html">Privacy & Poilicy</Link>
                </li>
                <li>
                  <Link to="courses.html">Terms & Condition</Link>
                </li>
                <li>
                  <Link to="courses.html">Customer Support</Link>
                </li>
                <li>
                  <Link to="courses.html">For Business </Link>
                </li>
                <li>
                  <Link to="courses.html">Support</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- End: Quick Link --> */}

          {/* <!-- Start: Useful Link --> */}
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="foo_widgetuseful_lnk">
              <h5>Useful Links</h5>
              <ul>
                <li>
                  <Link to="courses.html">About Us</Link>
                </li>
                <li>
                  <Link to="courses.html">Refer A Friend</Link>
                </li>
                <li>
                  <Link to="courses.html">Scolarship</Link>
                </li>
                <li>
                  <Link to="courses.html">Marketing</Link>
                </li>
                <li>
                  <Link to="courses.html">Free Courses</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- End: Social Link--> */}

          {/* <!-- Start: Contact Form --> */}
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="foo_widget footer_contact_form">
              <h5>Send Message</h5>
              <form method="post" action="mailer.php">
                <input
                  className="con-field"
                  name="email"
                  id="email"
                  type="text"
                  placeholder="Your Email"
                />
                <textarea
                  className="con-field"
                  name="email"
                  id="message"
                  placeholder="Your Message"
                >
                  {" "}
                </textarea>
                <input
                  type="submit"
                  id="submit-contact"
                  className="btn-alt"
                  value="Submit"
                />
              </form>
            </div>
          </div>
          {/* <!-- End: Contact Form--> */}
        </div>
      </div>
      {/* <!-- Start:Subfooter --> */}
      <div className="subfooter">
        <p>
          {" "}
          Copyright © 2021 <Link to="index.html">Aducat.</Link> All rights
          Reserved.
        </p>
      </div>
      {/* <!-- End:Subfooter --> */}
    </footer>
  )
}

export default Footer
