import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../../styles/homeComponentStyles/header.css"

const Header = () => {
  return (
    <div className="navigation navigation_two">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <StaticImage
              className="img-responsive"
              src="../../images/logo.png"
              alt="mainLogo"
              placeholder="blurred"
              layout="constrained"
            />
          </Link>
        </div>
        <div id="navigation" className="menu-wrap">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li className="has-sub">
              <Link to="index.html">Pages</Link>
              <ul>
                <li>
                  <Link to="course-category.html">Course Category</Link>
                </li>
                <li>
                  <Link to="courses.html">Our Course</Link>
                </li>
                <li>
                  <Link to="single-course.html">Course Details</Link>
                </li>
                <li>
                  <Link to="login.html">Login Page</Link>
                </li>
                <li>
                  <Link to="register.html">Register Page</Link>
                </li>
                <li>
                  <Link to="teachers.html">Instructor Page</Link>
                </li>
                <li>
                  <Link to="single-teacher.html">Instructor Details</Link>
                </li>
                <li>
                  <Link to="events.html">Event Page</Link>
                </li>
                <li>
                  <Link to="single-event.html">Event Details</Link>
                </li>
                <li>
                  <Link to="gallery.html">Gallery Page</Link>
                </li>
                <li>
                  <Link to="404.html">404 Page</Link>
                </li>
              </ul>
            </li>
            <li className="has-sub">
              <Link to="courses.html"> Courses</Link>
              <ul>
                <li>
                  <Link to="course-category.html">Course Category</Link>
                </li>
                <li>
                  <Link to="courses.html">Our Course</Link>
                </li>
                <li>
                  <Link to="single-course.html">Course Details</Link>
                </li>
              </ul>
            </li>
            <li className="has-sub">
              <Link to="blog.html">Blog</Link>
              <ul>
                <li>
                  <Link to="blog.html">Blog One</Link>
                </li>
                <li>
                  <Link to="single-blog.html">Blog Details</Link>
                </li>
                <li>
                  <Link to="events.html">Event Page</Link>
                </li>
                <li>
                  <Link to="single-event.html">Event Details</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        {/* <!-- End: navigation  --> */}
        <div className="header_sign">
          <Link to="#" className="more-link">
            Sign in
          </Link>
        </div>
        {/* <!-- End: Sign in --> */}
      </div>
      {/* <!--/ container --> */}
    </div>
  )
}

export default Header
